import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'sad_face_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="sad_face_outline_24"><g fill="currentColor"><path d="M15.562 16.303a.9.9 0 01-1.259-.133 2.487 2.487 0 00-.53-.432c-.383-.24-.97-.488-1.773-.488s-1.39.249-1.773.488c-.191.12-.381.26-.53.432a.9.9 0 01-1.4-1.132c.273-.329.616-.601.976-.826.617-.386 1.53-.762 2.727-.762s2.11.376 2.727.762c.36.224.706.497.976.826a.9.9 0 01-.14 1.265zM10.25 10.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM15 11.5A1.25 1.25 0 1015 9a1.25 1.25 0 000 2.5z" /><path clip-rule="evenodd" d="M12 2.1c-5.468 0-9.9 4.432-9.9 9.9s4.432 9.9 9.9 9.9 9.9-4.432 9.9-9.9-4.432-9.9-9.9-9.9zM3.9 12a8.1 8.1 0 1116.2 0 8.1 8.1 0 01-16.2 0z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SadFaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24SadFaceOutline: FC<Icon24SadFaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24SadFaceOutline as any).mountIcon = mountIcon;

export default Icon24SadFaceOutline;
